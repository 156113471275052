import React from "react";
import casteList from "../../common/data/CasteList";
import religionList from "../../common/data/ReligionList";
import ActionButton from "../../common/components/ActionButton";
import backgroundImage from "../../assets/bg.png";
import Select from "../../common/components/input/Select";
import InputBlock from "../../common/components/InputBlock";
import starList from "../../common/data/StarList";
import NumericInput from "../../common/components/input/NumericInput";

interface Props {
    caste: string;
    religion: string;
    identity: string;
    star: string;
    minAge: number;
    changeIdentity: (identity: string) => void;
    changeCaste: (caste: string) => void;
    changeReligion: (religion: string) => void;
    changeStar: (star: string) => void;
    changeMinAge: (minAge: number) => void;
    fetchProfiles: () => void;
}

export default function Filter(props: Props) {
    const identityList: string[] = ["Self", "Daughter", "Sister", "Relative", "Just seeing the profiles"];

    return (
        <div style={styles.container}>
            <h1 style={styles.heading}>Vennila Matrimony</h1>
            <InputBlock>
                <Select label={"Religion"} value={props.religion} onChange={props.changeReligion} valueList={religionList} defaultValue={"Any"}/>
                <Select label={"Caste"} value={props.caste} onChange={props.changeCaste} valueList={casteList} defaultValue={"Any"}/>
                <Select label={"Star"} value={props.star} onChange={props.changeStar} valueList={starList} defaultValue={"Any"}/>
                <NumericInput label={"Minimum Age"} value={props.minAge} onChange={props.changeMinAge} />
                <div style={{marginBottom: 10}}>You are viewing profiles for?</div>
                <Select label={""} value={props.identity} onChange={props.changeIdentity} valueList={identityList} defaultValue={"Any"}/>
                <div style={{alignSelf: "center", marginTop: 20}}>
                    <ActionButton text={"Next"} onPress={handleSearch(props)}/>
                </div>
            </InputBlock>
        </div>
    );
}

function handleSearch(props: Props) {
    return () => {
        props.fetchProfiles();
    }
}

const styles = {
    container: {
        backgroundImage: `url(${backgroundImage})`,
        height: "100%",
        backgroundSize: "cover",
        display: "flex",
        flexDirection: "column" as "column",
        alignItems: "center"
    },
    heading: {
        marginTop: 40,
        fontFamily: "'Dancing Script', cursive"
    },
    inputHeader: {
        marginBottom: 15,
        fontWeight: 700
    },
    input: {
        marginBottom: 20,
        width: "100%"
    }
}
