import React, { useState, useRef } from "react";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import ActionButton from "./ActionButton";
import AnalyticsService from "../service/AnalyticsService";

interface CaptchaButtonProps {
    onSuccess: (token: string) => void;
    text: string;
}

const SITE_KEY: string = "0a22eaa0-a494-45ac-90a8-8fb8be466d42"

const CaptchaButton: React.FC<CaptchaButtonProps> = (props: CaptchaButtonProps) => {
    const [isCaptchaVisible, setCaptchaVisible] = useState(false);
    const hcaptchaRef = useRef<HCaptcha | null>(null);

    const handleButtonClick = () => {
        AnalyticsService.logEvent("captcha_opened", {}).then(_ => {})
        setCaptchaVisible(true);
        hcaptchaRef.current?.execute();
    };

    const handleCaptchaSuccess = (token: string) => {
        AnalyticsService.logEvent("captcha_done", { status: true }).then(_ => {})
        setCaptchaVisible(false);
        props.onSuccess(token);
    };

    const handleCaptchaError = (error: string) => {
        AnalyticsService.logEvent("captcha_done", { status: false, error }).then(_ => {})
        setCaptchaVisible(false);
        alert("Captcha failed.");
    };

    return (
        <div>
            <ActionButton text={props.text} onPress={() => props.onSuccess(props.text)} />
            {isCaptchaVisible && (
                <HCaptcha
                    sitekey={SITE_KEY}
                    ref={hcaptchaRef}
                    size="invisible"
                    onVerify={handleCaptchaSuccess}
                    onError={(err: string) => handleCaptchaError(err.toString())}
                />
            )}
        </div>
    );
};

export default CaptchaButton;
