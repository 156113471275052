import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../common/redux/store";
import {socialModeEntered} from "../redux/thunks";
import Filter from "../components/Filter";
import {changeIdentity, changeReligion, changeStar, changeCaste, changeMinAge} from "../redux/slice";

const mapStateToProps = (state: RootState) => ({
    caste: state.social.filter.casteList[0],
    religion: state.social.filter.religionList[0],
    star: state.social.filter.starList[0],
    minAge: state.social.filter.minAge,
    identity: state.social.identity
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    changeCaste(caste: string) {
        dispatch(changeCaste(caste));
    },
    changeReligion(religion: string) {
        dispatch(changeReligion(religion));
    },
    changeIdentity(identity: string) {
        dispatch(changeIdentity(identity));
    },
    changeStar(star: string) {
        dispatch(changeStar(star));
    },
    changeMinAge(minAge: number) {
        dispatch(changeMinAge(minAge));
    },
    fetchProfiles() {
        dispatch(socialModeEntered());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
