import React from 'react';

export default function PrivacyPolicy() {
    return (
        <div style={styles.container}>
            <h1>Privacy Policy</h1>
            <p style={styles.text}>
                Vennila Matrimony is a matrimonial website released by Flutoz Software (OPC) Private Limited,
                India that deals with the development of application software products.

                The company is strongly committed to your right to
                privacy with regard to the information it collects from you. You
                acknowledge that you are disclosing information voluntarily. By
                accessing /using the Neetbio website hereinafter referred to as the
                ‘Website’ and/or by providing your information, you consent to the
                collection and use of the information you disclose on the website in
                accordance with this Privacy Policy. If you do not agree for use of your
                information, please do not use or access this website.
            </p>

            <h5 style={styles.subheading}>
                What information do you need to give in order to use this website?
            </h5>
            <p style={styles.text}>
                The information the company gathers from members and visitors who apply for the various services our app
                offers includes,
                but may not be limited to name, date of birth, educational qualifications a user-specified password,
                mailing address and mobile number.

                The user information the company collects depends on the context of your interactions with the
                website, the choices you make and the features you use.

                The company may use your personal information for analysis of data, usage trends and to evaluate and
                improve
                our website, marketing research, preventing of frauds. In our efforts to continually improve our product
                and
                service offerings, the company collects and analyses demographic and profile data about its users'
                activity
                on our website. We identify and use your IP address to help diagnose problems with our server, and to
                administer our website. Your IP address is also used to help identify you and to gather broad
                demographic information.
            </p>

            <h5 style={styles.subheading}>
                How the app uses the information it collects/tracks?
            </h5>
            <p style={styles.text}>
                The company collects information for data analysis, identifying usage trends,determining the
                effectiveness of our promotional campaigns and to evaluate and improve our app, features and services,
                marketing
                research from our users primarily to ensure that we are able to fulfill your requirements and to deliver
                better
                experience.
            </p>

            <h5 style={styles.subheading}>
                With whom the company shares the information it collects/tracks?
            </h5>
            <p style={styles.text}>
                The company may share such identifiable information with our
                associates/affiliates/subsidiaries and such
                associates/affiliates/subsidiaries may market to you as a result of such
                sharing. Any information you give us is held with the utmost care and
                security. The company is also bound to cooperate fully should a
                situation arise where we are required by law or legal process to provide
                the information about the user.

                Where required or permitted by law,
                information may be provided to others, such as regulators and law
                enforcement agencies or to protect the rights ,property or personal
                safety of the general public . The company may voluntarily share your
                information with law enforcement agencies / payment gateway service
                providers / anti-fraud solution provider(s) if it feels that the user is
                of suspicious nature.

                From time to time, the company may consider
                corporate transactions such as a merger, acquisition, reorganization,
                asset sale, or similar. In these instances, the company may transfer or
                allow access to information to enable the assessment and undertaking of
                that transaction. If the company buys or sells any business or assets,
                personal information may be transferred to third parties involved in the
                transaction. Our website shall link to other websites/apps that may
                collect personally identifiable information about you. The company is
                not responsible for the privacy policy or the contents of those linked
                websites/apps.
            </p>

            <h5 style={styles.subheading}>How Long Do We Keep Your Information?</h5>
            <p style={styles.text}>
                As stipulated in the Privacy Policy we will retain the information we
                collect from users under the following circumstances: For as long as the
                users subscribe to our services to meet their suitable purpose(s) for
                which it was collected, for the sake of enforcing agreements, for
                performing audits, for resolving any form of disputes, for establishing
                legal defences, for pursuing legitimate businesses and to comply with
                the relevant applicable laws.{' '}
            </p>

            <h5 style={styles.subheading}>
                What are the Security Precautions in respect of your personal information?
            </h5>
            <p style={styles.text}>
                The company aims to protect your personal information through a system
                of organizational and technical security measures. The company has
                implemented appropriate internal control measures designed to protect
                the security of any personal information it processes. However, please
                also remember that the company cannot guarantee that the internet itself
                is 100% secure. The company employs its best effort in protecting your
                information against unauthorised access.
            </p>

            <h5 style={styles.subheading}>Change of Privacy Policy</h5>
            <p style={styles.text}>
                The company may change this Privacy Policy without notice to you from
                time to time. However, changes will be updated in the Privacy Policy
                page.
            </p>
        </div>
    );
}

const styles = {
    text: {
        color: 'white',
        textAlign: 'justify' as 'justify',
        fontFamily: "Teko, 'normal'",
        fontWeight: 200,
        letterSpacing: 1,
        fontSize: '1.2vw',
        lineHeight: 1,
    },
    subheading: {
        // color: '#0ca950',
        color: 'white',
        fontFamily: "Teko, 'normal'",
        letterSpacing: 1,
        fontSize: '1.5vw',
    },
    container: {
        color: '#0ca950',
        backgroundColor: '#323337',
        padding: 50,
        minHeight: '100%',
    },
};
