export default class DateManipulator {
    static formatDate(date: Date): string {
        return (
            date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()
        );
    }

    static formatTime(date: Date): string {
        const minutes: string =
            date.getMinutes() > 9
                ? date.getMinutes().toString()
                : '0' + date.getMinutes();
        if (date.getHours() > 12) {
            return date.getHours() - 12 + ':' + minutes + ' PM';
        } else if (date.getHours() === 12) {
            return date.getHours() + ':' + minutes + ' PM';
        } else if (date.getHours() > 0) {
            return date.getHours() + ':' + minutes + ' AM';
        } else {
            return '12:' + minutes + ' AM';
        }
    }
}