import React from 'react'

interface Props {
    navigate: () => void
    genderLabel: string
    shortExplanation: string
    longExplanation: string
}

export default function (props: Props) {
    return (
        <div>
            <div style={styles.buttonBlock}>
                <button onClick={props.navigate} style={styles.genderButton}>
                    {props.genderLabel}
                </button>
                <p>{props.shortExplanation}</p>
            </div>
            <p style={styles.explanation}>
                {props.longExplanation}
            </p>
        </div>
    )
}

const styles = {
    explanation: {
        margin: 10,
        textAlign: "center" as "center",
        fontSize: 15
    },
    genderButton: {
        paddingTop: 15,
        paddingBottom: 15,
        margin: 10,
        backgroundColor: "#9999FF",
        border: "none",
        fontFamily: "'Dancing Script', cursive",
        borderRadius: 10,
        color: "white",
        fontSize: 18,
        fontWeight: "bold" as "bold",
        width: 100
    },
    buttonBlock: {
        display: "flex",
        flexDirection: "row" as "row",
        alignItems: "center"
    },
    question: {
        alignSelf: "center",
        marginTop: 40
    }
}