import React from "react";
import styles from "./styles";

interface Props {
    label: string;
    value: Date;
    onChange: (value: Date) => void;
}

export default function DateTimeInput(props: Props): JSX.Element {
    const formattedDateString = getDateInputFormatDateString(props);
    return (
        <div style={{width:'100%'}}>
            <div style={styles.inputHeader}>{props.label}</div>
            <input
                type="datetime-local"
                value={formattedDateString}
                onChange={(e) => {
                    const value: Date = new Date(e.target.value);
                    props.onChange(value)
                }}
                style={styles.input}
            />
        </div>
    )
}

function getDateInputFormatDateString(props: Props) {
    return new Date(props.value.getTime() - (props.value.getTimezoneOffset() * 60000))
        .toISOString()
        .slice(0, 16);
}
