import React from "react";
import TwoBlockTitle from "../../common/components/TwoBlockTitle";
import InputBlock from "../../common/components/InputBlock";
import TextInput from "../../common/components/input/TextInput";
import Container from "../../common/components/Container";
import CaptchaButton from "../../common/components/CaptchaButton";

interface Props {
    mobileNumber: string
    changeMobileNumber: (mobileNumber: string) => void
    setTime: (time: number) => void
    setOTPSent: (otpSent: boolean) => void
    sendCode: (token: string) => void
}

export default function MobileEnter(props: Props) {
    return (
        <Container>
            <TwoBlockTitle/>
            <InputBlock>
                <TextInput
                    label={"Phone Number"}
                    value={props.mobileNumber}
                    onChange={props.changeMobileNumber}
                />
                <CaptchaButton text={"Send Confirmation Code"} onSuccess={sendOTP(props)}/>
            </InputBlock>
        </Container>
    )
}

function sendOTP(props: Props) {
    return (token: string) => {
        if (validateMobileNumber(props.mobileNumber)) {
            props.sendCode(token);
            props.setOTPSent(true);
            props.setTime(60);
        } else {
            alert('Mobile number invalid');
        }
    }
}

const validateMobileNumber = (mobileNumber: string): boolean => {
    return mobileNumber.length === 10;
};