import React from "react";
import Modal from 'react-modal';

interface Props {
    isOpen: boolean;
}

export default function LoadingModal(props: Props) {
    return (
        <Modal
            isOpen={props.isOpen}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <div style={{fontSize: 20}}>Loading...</div>
        </Modal>
    )
}

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        background: "#603693",
        border: "none"
    },
};
