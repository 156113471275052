import React, {useState} from "react";
import backgroundImage from "../../assets/bg-card-list.png";
import Profile from "../../common/model/Profile";
import ProfileCard from "./ProfileCard";
import ActionButton from "../../common/components/ActionButton";
import PayModal from "./PayModal";

interface ProfileCardListProps {
    profileList: Profile[];
    currentProfileIndex: number;
    profileCount: number;
    offset: number;
    changeCurrentPositionIndex: (index: number) => void;
    socialModeEntered: () => void;
    refreshList: () => void;
    viewProfile: () => void;
    navigateToFilter: () => void;
    getNumber: (profile: Profile, token: string) => void;
}

export default function ProfileCardList(props: ProfileCardListProps) {
    // const [modal, setModal] = useState<boolean>(false);

    return (
        <div style={styles.container}>
            {/*<PayModal isOpen={modal} closeModal={() => {setModal(false)}} onClick={() => {props.getNumber(props.profileList[props.currentProfileIndex])}} />*/}
            <h1 style={styles.heading}>Vennila Matrimony</h1>
            <div style={styles.countString}>
                {props.offset + props.currentProfileIndex + 1} of {props.profileCount}
            </div>
            <ProfileCard
                profile={props.profileList[props.currentProfileIndex]}
                navigateToProfileView={props.viewProfile}
                getNumber={props.getNumber}
            />
            <div style={styles.actionContainer}>
                <ActionButton rounded text={'←'} onPress={() => {
                    props.changeCurrentPositionIndex(props.currentProfileIndex - 1);
                }}/>
                <ActionButton text={"Filter"} onPress={props.navigateToFilter}/>
                <ActionButton rounded text={'→'} onPress={() => {
                    props.changeCurrentPositionIndex(props.currentProfileIndex + 1);
                }}/>
            </div>
        </div>
    )
}

const styles = {
    container: {
        backgroundImage: `url(${backgroundImage})`,
        height: "100%",
        backgroundSize: "cover",
        display: "flex",
        flexDirection: "column" as "column",
        alignItems: "center"
    },
    heading: {
        marginTop: 40,
        fontFamily: "'Dancing Script', cursive"
    },
    countString: {
        marginBottom: 20,
        fontWeight: "bold" as "bold"
    },
    actionContainer: {
        display: "flex",
        flexDirection: "row" as "row",
        justifyContent: "space-around",
        alignItems: "center",
        width: "100%",
        marginTop: 20
    },
    actionButton: {
        background: "#cb6ce6",
        width: 50,
        height: 50,
        borderRadius: 50,
        fontWeight: "bold" as "bold",
        display: "flex",
        alignItems: "center" as "center",
        justifyContent: "center" as "center"
    }
}
