import React from 'react';

const TwoBlockTitle = () => (
        <div>
            <h1 style={styles.titleOne}>Vennila</h1>
            <h1 style={styles.titleTwo}>Matrimony</h1>
        </div>
    )
;

const styles = {
    titleOne: {
        margin: 0,
        marginLeft: "40px",
        marginTop: 40,
        fontFamily: "'Dancing Script', cursive"
    },
    titleTwo: {
        margin: 0,
        marginLeft: "70px",
        fontFamily: "'Dancing Script', cursive"
    },
}

export default TwoBlockTitle;
