import React from "react";

interface ProfileFieldProps {
    name: string;
    value: string;
}

export default function ProfileField(props: ProfileFieldProps) {
    return (
        <div style={{display: "flex", flexDirection: "column", flex: 1, margin: 10}}>
            <div style={{fontWeight: "bold"}}>{props.name}</div>
            <div>{props.value}</div>
        </div>
    )
}
