const religionList: string[] = [
    // 'Any',
    'Hindu',
    'Christian',
    'Muslim',
    'Sikh',
    'Jain',
    'Buddhism',
    'Zoroastrian',
];

export default religionList;
