import React, {useEffect, useState} from "react";
import MobileEnter from "./MobileEnter";
import CodeEnter from "./CodeEnter";

interface Props {
    mobileNumber: string
    changeMobileNumber: (mobileNumber: string) => void
    code: string
    changeCode: (code: string) => void
    attemptLogin: () => void
    sendCode: (token: string) => void
}

export default function Login(props: Props) {

    const RESEND_OTP_TIMER_SECONDS = 60
    const MILLIS_IN_A_SECONDS = 1000

    const [otpSent, setOtpSent] = useState(false);
    const [time, setTime] = useState(RESEND_OTP_TIMER_SECONDS);

    useEffect(() => {
        setTime(RESEND_OTP_TIMER_SECONDS);
        setInterval(() => {
            setTime((time: number) => time ? time - 1 : time);
        }, MILLIS_IN_A_SECONDS);
    }, []);

    if (!otpSent) {
        return (
            <MobileEnter
                mobileNumber={props.mobileNumber}
                changeMobileNumber={props.changeMobileNumber}
                setTime={setTime}
                setOTPSent={setOtpSent}
                sendCode={props.sendCode}
            />
        )
    } else {
        return (
            <CodeEnter
                code={props.code}
                changeCode={props.changeCode}
                time={time}
                setTime={setTime}
                setOTPSent={setOtpSent}
                attemptLogin={props.attemptLogin}
                sendCode={props.sendCode}
            />
        );
    }
}
