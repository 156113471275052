import React from "react";

interface Props {
    children?: React.ReactNode;
}

export default function InputBlock(props: Props) {
    return (
        <div style={styles.inputBlock}>
            {props.children}
        </div>
    )
}

const styles = {
    inputBlock: {
        display: "flex",
        flexDirection: "column" as "column",
        justifyContent: "center" as "center",
        flex: 1,
        alignItems: "flex-start" as "flex-start",
        marginBottom: 250
    }
}