import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import initialState from './initialState';
import Profile from "../../common/model/Profile";
import ProfileFilter from "../../common/model/ProfileFilter";

const Slice = createSlice({
    name: 'Social',
    initialState,
    reducers: {
        changeProfileCount(state, action: PayloadAction<number>) {
            state.profileCount = action.payload;
        },

        changeProfileList(state, action: PayloadAction<Profile[]>) {
            state.profileList = action.payload;
            state.currentProfileIndex = Math.min(
                state.currentProfileIndex,
                Math.max(0, action.payload.length - 1),
            );
            state.newFetch = true;
        },

        changeFilter(state, action: PayloadAction<ProfileFilter>) {
            state.filter = action.payload;
        },

        changeCaste(state, action: PayloadAction<string>) {
            state.filter.casteList = action.payload.length ? [action.payload] : [];
        },

        changeReligion(state, action: PayloadAction<string>) {
            state.filter.religionList = action.payload.length ? [action.payload] : [];
        },

        changeStar(state, action: PayloadAction<string>) {
            state.filter.starList = action.payload.length ? [action.payload] : [];
        },

        changeMinAge(state, action: PayloadAction<number>) {
            state.filter.minAge = action.payload;
        },

        changeCurrentProfileIndex(state, action: PayloadAction<number>) {
            if (action.payload + 1 <= state.profileList.length) {
                state.currentProfileIndex = Math.max(0, action.payload);
            }
        },

        changeOffset(state, action: PayloadAction<number>) {
            state.offset = action.payload;
        },

        changeNewFetch(state, action: PayloadAction<boolean>) {
            state.newFetch = action.payload;
        },

        changeIdentity(state, action: PayloadAction<string>) {
            state.identity = action.payload;
        },
    },
});

export default Slice.reducer;

export const {
    changeProfileCount,
    changeCurrentProfileIndex,
    changeFilter,
    changeOffset,
    changeProfileList,
    changeStar,
    changeMinAge,
    changeReligion,
    changeCaste,
    changeIdentity
} = Slice.actions;
