const rasiList: string[] = [
    'Mesham',
    'Rishabam',
    'Mithunam',
    'Kadagam',
    'Simmam',
    'Kanni',
    'Thulam',
    'Viruchigam',
    'Thanusu',
    'Magaram',
    'Kumbam',
    'Meenam',
];

export default rasiList;
