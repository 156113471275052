import React from "react";
import { casteList, starList, rasiList, qualificationList,
    occupationTypeList, religionList } from "../../common/data/Constants";
import ActionButton from "../../common/components/ActionButton";
import backgroundImage from "../../assets/bg-card-list.png";
import Select from "../../common/components/input/Select"
import Profile from "../../common/model/Profile";
import TextInput from "../../common/components/input/TextInput";
import NumericInput from "../../common/components/input/NumericInput";
import DateTimeInput from "../../common/components/input/DateTimeInput";
import InputBlock from "../../common/components/InputBlock";

interface Props {
    profile: Profile;
    changeProfile: (profile: Profile) => void;
    saveProfile: () => void
}

export default function ProfileComponent(props: Props) {
    const validHeights: number[] = Array.from({ length: 190 - 150 + 1 }, (_, i) => 150 + i);

    return (
        <div style={styles.container}>
            <h1 style={styles.heading}>Vennila Matrimony</h1>
            <InputBlock>
                <TextInput
                    label={"Name"}
                    value={props.profile.name}
                    onChange={changeProperty('name')}
                />
                <Select
                    label={"Height (in cm)"}
                    value={props.profile.height.toString()}
                    onChange={(value: string) => {
                        changeNumericProperty('height')(parseInt(value));
                    }}
                    valueList={validHeights.map(it => it.toString())}
                />
                <DateTimeInput
                    label={"Birth Date/Time"}
                    value={new Date(props.profile.dateOfBirth)}
                    onChange={changeDateTimeProperty('dateOfBirth')}
                />
                <Select
                    label={"Religion"}
                    value={props.profile.religion}
                    onChange={changeProperty('religion')}
                    valueList={religionList}
                />
                <Select
                    label={"Caste"}
                    value={props.profile.caste}
                    onChange={changeProperty('caste')}
                    valueList={casteList}
                />
                <Select
                    label={"Star"}
                    value={props.profile.star}
                    onChange={changeProperty('star')}
                    valueList={starList}
                />
                <Select
                    label={"Rasi"}
                    value={props.profile.rasi}
                    onChange={changeProperty('rasi')}
                    valueList={rasiList}
                />
                <Select
                    label={"Lagnam"}
                    value={props.profile.lagnam}
                    onChange={changeProperty('lagnam')}
                    valueList={rasiList}
                />
                <TextInput
                    label={"Current Location"}
                    value={props.profile.workLocation}
                    onChange={changeProperty('workLocation')}
                />
                <Select
                    label={"Qualification *"}
                    value={props.profile.educationalQualification}
                    onChange={changeProperty('educationalQualification')}
                    valueList={qualificationList}
                />
                <TextInput
                    label={"Branch / Subject"}
                    value={props.profile.educationalQualification}
                    onChange={changeProperty('educationalQualification')}
                />
                <Select
                    label={"Occupation *"}
                    value={props.profile.employmentType}
                    onChange={changeProperty('employmentType')}
                    valueList={occupationTypeList}
                />
                <NumericInput
                    label={"Monthly Income (Rs.)"}
                    value={props.profile.salary}
                    onChange={changeNumericProperty('salary')}
                />
                <TextInput
                    label={"Working In (Company)"}
                    value={props.profile.company}
                    onChange={changeProperty('company')}
                />
                <TextInput
                    label={"Father's Name *"}
                    value={props.profile.father}
                    onChange={changeProperty('father')}
                />
                <TextInput
                    label={"Mother's Name *"}
                    value={props.profile.mother}
                    onChange={changeProperty('mother')}
                />
                <NumericInput
                    label={"Number of Brothers"}
                    value={props.profile.brothers}
                    onChange={changeNumericProperty('brothers')}
                />
                <NumericInput
                    label={"Number of Married Brothers"}
                    value={props.profile.brothersMarried}
                    onChange={changeNumericProperty('brothersMarried')}
                />
                <NumericInput
                    label={"Number of Sisters"}
                    value={props.profile.sisters}
                    onChange={changeNumericProperty('sisters')}
                />
                <NumericInput
                    label={"Number of Married Sisters"}
                    value={props.profile.sistersMarried}
                    onChange={changeNumericProperty('sistersMarried')}
                />
                <TextInput
                    label={"Contact Address"}
                    value={props.profile.contactAddress}
                    onChange={changeProperty('contactAddress')}
                />
                <ActionButton text={"Save"} onPress={saveProfile} />
            </InputBlock>
        </div>
    );

    function saveProfile() {
        if (!props.profile.caste || !props.profile.religion || props.profile.caste === '-' || props.profile.religion === '-') {
            alert("Please select some caste/religion.");
            return;
        }
        props.saveProfile();
    }

    function changeProperty(key: string) {
        return (value: string) => {
            props.changeProfile({...props.profile, [key]: value});
        }
    }

    function changeNumericProperty(key: string) {
        return (value: number) => {
            props.changeProfile({...props.profile, [key]: value});
        }
    }

    function changeDateTimeProperty(key: string) {
        return (value: Date) => {
            props.changeProfile({...props.profile, [key]: value.getTime()});
        }
    }
}

const styles = {
    container: {
        backgroundImage: `url(${backgroundImage})`,
        height: "100%",
        backgroundSize: "cover",
        display: "flex",
        flexDirection: "column" as "column",
        alignItems: "center",
        overflowY: "auto" as 'auto',
    },
    heading: {
        marginTop: 40,
        fontFamily: "'Dancing Script', cursive"
    }
}
