import {AppDispatch, AppThunk, RootState} from "../../common/redux/store";
import Profile from "../../common/model/Profile";
import {changeCurrentProfileIndex, changeOffset, changeProfileCount, changeProfileList} from "./slice";
import ProfileFilter from "../../common/model/ProfileFilter";
import SocialService from "../../common/service/SocialService";
import {batch} from 'react-redux';
import SocialNavigation from "../SocialNavigation";
import AnalyticsService from "../../common/service/AnalyticsService";
import {changeLoading} from "../../common/redux/slice";

export const formatFilter = (filter: ProfileFilter) => {
    filter = {
        ...filter,
        gender: 'M'
    };
    if (filter.maxAge === 41) {
        filter.maxAge = 120;
    }
    return filter;
};


export const socialModeEntered = (): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(changeOffset(0));
    dispatch(changeCurrentProfileIndex(0));
    dispatch(fetchProfiles());
};

const fetchProfiles = (): AppThunk => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(changeLoading(true));
    const state: RootState = getState();
    const filter: ProfileFilter = state.social.filter;
    const formattedFilter: ProfileFilter = formatFilter(filter);
    const profileListPromise: Promise<Profile[]> = SocialService.fetchProfileList(
        formattedFilter,
        state.social.offset,
    );
    const profileCountPromise: Promise<number> = SocialService.fetchProfileCount(
        formattedFilter,
    );
    await AnalyticsService.logEvent("filter", formattedFilter);
    await AnalyticsService.logEvent("identity", state.social.identity);
    const profileList: Profile[] = await profileListPromise;
    const profileCount: number = await profileCountPromise;
    batch(() => {
        dispatch(changeProfileList(profileList));
        dispatch(changeProfileCount(profileCount));
    });
    dispatch(changeLoading(false));
    if (profileCount === 0) {
        alert("No matching profiles found. Please try with different caste/religion.");
        return;
    }
    SocialNavigation.navigateToProfileCardList();
};

export const fetchMobile = (profile: Profile, captchaToken: string): AppThunk => async (dispatch: AppDispatch, getState: () => RootState) => {
    await AnalyticsService.logEvent("get_mobile_number", profile.id)
    await SocialService.fetchMobile(profile.id, captchaToken);
};

export const refreshProfileList = (): AppThunk => async (dispatch: AppDispatch, getState: () => RootState) => {
    const state: RootState = getState();
    const filter: ProfileFilter = state.social.filter;
    const profileCountPromise: Promise<number> = SocialService.fetchProfileCount(
        formatFilter(filter),
    );
    const profileCount: number = await profileCountPromise;
    if (profileCount !== state.social.profileCount) {
        const profileListPromise: Promise<Profile[]> = SocialService.fetchProfileList(
            formatFilter(filter),
            state.social.offset,
        );
        const profileList: Profile[] = await profileListPromise;
        batch(() => {
            dispatch(changeProfileList(profileList));
            dispatch(changeProfileCount(profileCount));
        });
    }
};

export const changeCurrentProfileIndexThunk = (
    index: number,
): AppThunk => async (dispatch: AppDispatch, getState: () => RootState) => {
    const state: RootState = getState();
    const offset: number = state.social.offset;
    if (index === -1) {
        if (offset) {
            const newOffset: number = Math.max(0, offset - 100);
            const filter: ProfileFilter = formatFilter(state.social.filter);
            const profileListPromise: Promise<Profile[]> = SocialService.fetchProfileList(filter, newOffset);
            const countPromise: Promise<number> = SocialService.fetchProfileCount(filter);
            const profileList: Profile[] = await profileListPromise;
            const profileCount: number = await countPromise;
            batch(() => {
                dispatch(changeProfileList(profileList));
                dispatch(changeProfileCount(profileCount));
                dispatch(changeOffset(newOffset));
                dispatch(changeCurrentProfileIndex(profileList.length - 1));
            });
        }
    } else if (index >= 100) {
        if (state.social.profileList.length + state.social.offset < state.social.profileCount) {
            const newOffset: number = offset + 100;
            const filter: ProfileFilter = formatFilter(state.social.filter);
            const profileListPromise: Promise<Profile[]> = SocialService.fetchProfileList(filter, newOffset);
            const countPromise: Promise<number> = SocialService.fetchProfileCount(filter);
            const profileList: Profile[] = await profileListPromise;
            const profileCount: number = await countPromise;
            batch(() => {
                dispatch(changeProfileList(profileList));
                dispatch(changeProfileCount(profileCount));
                dispatch(changeOffset(newOffset));
                dispatch(changeCurrentProfileIndex(0));
            });
        }
    } else {
        dispatch(changeCurrentProfileIndex(index));
    }
    AnalyticsService
        .logEvent("profile_navigation", true)
        .then(() => {})
};
