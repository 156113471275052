const styles = {
    input: {
        marginBottom: 20,
        width: "100%",
        padding: 7,
        borderRadius: 5,
        border: 'none'
    },
    inputHeader: {
        marginTop: 5,
        marginBottom: 15,
        fontWeight: 700,
        fontSize: 20,
        fontFamily: "'Dancing Script', cursive"
    },
}

export default styles;