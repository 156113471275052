import React from "react";
import styles from "./styles";

interface Props {
    label: string;
    value: number;
    minValue?: number;
    maxValue?: number;
    onChange: (value: number) => void;
}

export default function NumericInput(props: Props): JSX.Element {

    const [value, setValue] = React.useState("");

    function isNumber(value: string) {
        const num: number = parseInt(value, 10);
        return /^\d+$/.test(value)
            && (props.minValue ? (num >= props.minValue) : true)
            && (props.maxValue ? (num <= props.maxValue) : true)
    }

    return (
        <div style={{width: '100%'}}>
            <div style={styles.inputHeader}>{props.label}</div>
            <input
                value={value}
                onChange={(e) => {
                    setValue(e.target.value)
                    if (isNumber(e.target.value)) {
                        props.onChange(parseInt(e.target.value))
                    }
                }}
                style={styles.input}
            />
        </div>
    )
}
