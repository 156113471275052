import React from 'react';

interface ActionButtonProps {
    text: string;
    onPress: () => void;
    rounded?: boolean;
}

const ActionButton = (props: ActionButtonProps) => (
    <div onClick={props.onPress} style={props.rounded ? styles.roundedButton : styles.actionButton}>
        {props.text}
    </div>
);

const styles = {
    actionButton: {
        background: "#cb6ce6",
        paddingTop: '0.5rem',
        paddingBottom: '0.5rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        borderRadius: '0.2rem',
        fontFamily: "'Montserrat', cursive",
        // fontSize: '1.2rem',
        fontWeight: "500",
        textAlign: "center" as 'center',
        userSelect: "none" as 'none',
        cursor: "pointer",
    },
    roundedButton: {
        background: "#cb6ce6",
        padding: "0.6rem",
        paddingRight: "0.8rem",
        paddingLeft: "0.8rem",
        borderRadius: "100vh",
        fontSize: '1.2rem',
        fontWeight: "bold",
        userSelect: "none" as 'none',
        cursor: "pointer",
    }
}

export default ActionButton;
