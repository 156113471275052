import Profile from "../model/Profile";
import ProfileFilter from "../model/ProfileFilter";
import Controller from "./Controller";

class SocialService {
    async fetchProfileList(filter: ProfileFilter, offset: number): Promise<Profile[]> {
        return Controller.fetch('/social/fetch/all', {filter, offset});
    }

    async fetchProfileCount(filter: ProfileFilter): Promise<number> {
        return Controller.fetch('/social/fetch/count', {filter});
    }

    fetchPaymentHandler = (id: string, orderId: string) => async (response: any) => {
        console.log(response, id, orderId, "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX")
        const mobileNumber = await Controller.fetch('/pay/confirm', {
            id,
            orderId,
            paymentId: response.razorpay_payment_id,
            hash: response.razorpay_signature,
        });
        alert("Mobile number is: " + mobileNumber.mobileNumber)
    };

    async fetchMobile(id: string, captchaToken: string): Promise<void> {
        // const {orderId, amount} = await PaymentService.payRequest(id);
        // console.log(orderId, amount)
        // await PaymentService.openGateway(id, orderId, amount);
        const mobileNumber = await Controller.fetch('/social/fetch/mobile', {
            userId: id,
            captchaToken
        })
        alert("Mobile number is: " + mobileNumber)
    }
}

export default new SocialService();
