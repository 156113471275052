import Profile from "../model/Profile";
import Controller from "./Controller";

class ProfileService {
    async fetchProfile(): Promise<Profile> {
        return Controller.fetch('/profile/fetch', {});
    }

    async saveProfile(profile: Profile): Promise<void> {
        await Controller.fetchVoid('/profile/save', {profile});
    }
}

export default new ProfileService();