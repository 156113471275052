import React from "react";
import backgroundImage from "../../assets/bg.png";

interface Props {
    children?: React.ReactNode;
}

export default function Container(props: Props) {
    return (
        <div style={styles.container}>
            {props.children}
        </div>
    )
}

const styles = {
    container: {
        backgroundImage: `url(${backgroundImage})`,
        height: "100%",
        backgroundSize: "cover",
        fontFamily: "'Dancing Script', cursive",
        display: "flex",
        flexDirection: "column" as "column",
        alignItems: "center",
        overflowY: "auto" as 'auto',
    }
}