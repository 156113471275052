const starList: string[] = [
    'N/A',
    'Aswini',
    'Bharani',
    'Karthigai',
    'Rogini',
    'Mirugasirisham',
    'Thiruvadhirai',
    'Punarpoosam',
    'Poosam',
    'Ayilyam',
    'Magam',
    'Pooram',
    'Uthiram',
    'Hastham',
    'Chithirai',
    'Swathi',
    'Visagam',
    'Anusham',
    'Kettai',
    'Moolam',
    'Pooradam',
    'Uthiradam',
    'Thiruvonam',
    'Avittam',
    'Sathayam',
    'Poorattadhi',
    'Uthirattadhi',
    'Revathi',
];

export default starList;
