import React from "react";
import backgroundImage from "../../assets/bg-card-list.png";

export default function Register() {
    return (
        <div style={styles.container}>
            <h1 style={styles.heading}>Vennila Matrimony</h1>
            <div style={styles.inputBlock}>
                <div style={styles.inputHeader}>We've got your contact information. We'll contact you shortly regarding
                    the next steps.
                </div>
            </div>
        </div>
    );
}

const styles = {
    container: {
        backgroundImage: `url(${backgroundImage})`,
        height: "100%",
        backgroundSize: "cover",
        display: "flex",
        flexDirection: "column" as "column",
        alignItems: "center"
    },
    heading: {
        marginTop: 40,
        fontFamily: "'Dancing Script', cursive"
    },
    inputHeader: {
        marginBottom: 15,
        fontWeight: 700
    },
    input: {
        marginBottom: 20,
        width: "100%"
    },
    inputBlock: {
        display: "flex",
        flexDirection: "column" as "column",
        justifyContent: "center" as "center",
        flex: 1,
        alignItems: "flex-start" as "flex-start",
        marginBottom: 250
    }
}
