import Controller from "./Controller";
import Cookies from "js-cookie";

class AnalyticsService {
    async logEvent(name: string, payload: any): Promise<void> {
        const id = Cookies.get("userId")
        await Controller.fetchVoid('business/analytics/log', { id, name, payload, timestamp: new Date().getTime() });
    }
}

export default new AnalyticsService()
