import {Gender} from "./Gender";

export default class ProfileFilter {
    minAge: number = 0;
    maxAge: number = 120;
    religionList: string[] = [];
    starList: string[] = [];
    casteList: string[] = [];
    qualificationList: string[] = [];
    occupationTypeList: string[] = [];
    gender: Gender | 'M' = 'M';
    maritalStatus: 'never' | 'divorced' | 'widowed' = 'never';
    heightLimit: number = 180;
}
