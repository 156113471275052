import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../common/redux/store";
import {saveProfile} from "../redux/thunks";
import {changeProfile} from "../redux/slice";
import ProfileComponent from "../components/Profile";
import Profile from "../../common/model/Profile";

const mapStateToProps = (state: RootState) => ({
    profile: state.register.profile,
    modifiedProfile: state.register.modifiedProfile
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    changeProfile(profile: Profile) {
        dispatch(changeProfile(profile));
    },
    saveProfile() {
        dispatch(saveProfile());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileComponent);
