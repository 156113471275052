const occupationTypeList: string[] = [
    'Not Working',
    'Agriculture',
    'Bank Clerk',
    'Bank Officer / Manager',
    'Business Owner / Entrepreneur ',
    'Computer - Software / IT',
    'Computer - Hardware',
    'Defence Services',
    'Doctor',
    'Engineer',
    'Government Employee - State',
    'Government Employee - Central',
    'Government Others / PSU',
    'Lawyer ',
    'Private - Company Employee',
    'Private - Factory Employee',
    'Private - Clerk / Sales',
    'Private - Officer / Supervisor',
    'Private - Professional',
    'Private - Self-employed',
    'Private - Technician',
    'Private - Delivery Services',
    'Private - Media, Communication, Telecom',
    'Private - Marketing / Representatives',
    'Private - Transport',
    'Private - Others',
    'Railways',
    'Shop / Store owner',
    'Teacher - Government',
    'Teacher - Private',
    'Transport - Govt./State/PSU',
    'Others',
];

export default occupationTypeList;
