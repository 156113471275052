import React from "react";
import backgroundImage from "../../assets/bg.png";
import TwoBlockTitle from "../../common/components/TwoBlockTitle";
import Container from "../../common/components/Container";
import OptionBlock from "./OptionBlock";

interface LandingProps {
    navigateToRegister: () => void;
    navigateToSocial: () => void;
}

export default function Landing(props: LandingProps) {
    return (
        <Container>
            <TwoBlockTitle/>
            <h2 style={styles.question}>You are?</h2>
            <div style={styles.optionBlockContainer}>
                <OptionBlock
                    genderLabel={"Male"}
                    navigate={props.navigateToRegister}
                    shortExplanation={"Register in this website for FREE"}
                    longExplanation={"(You can't view bride profiles as we don't collect bride information for their privacy.)"}
                />
                <OptionBlock
                    genderLabel={"Female"}
                    navigate={props.navigateToSocial}
                    shortExplanation={"View groom profiles"}
                    longExplanation={"(You don't have to provide any information but you can view groom profiles.)"}
                />
            </div>
        </Container>
    )
}

const styles = {
    optionBlockContainer: {
        padding: 20
    },
    question: {
        alignSelf: "center",
        marginTop: 40
    }
}
